import { Box } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import ThankYouForYourOrderResponsiveDialog from '~/src/components/orders/ThankYouForYourOrderResponsiveDialog'
import useGetProductsTemplate from '~/src/pages/products/styles/useGetProductsTemplate'
import ProductListWrappedByCategory from '~/src/components/products/product-list/ProductListWrappedByCategory'
import ProductsSpotlighted from '~/src/components/products/products-spotlighted'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import useLogViewAllProducts from '~/src/hooks/analytics/ecommerce/useLogViewAllProducts'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { Constants } from '~/src/helpers/constants'

const Products = () => {
  const pageName: keyof typeof RoutesEnum = 'MENU'
  const [ searchParams ] = useSearchParams()
  const styles = useGetProductsTemplate()

  useLogViewAllProducts()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5,
        delay: Constants.MOTION_ANIMATION_DELAY
      }}
    >
      <DocumentTitleInjector pageName={pageName} />
      <Box
        id='products_list_container'
        sx={styles.getContainerSx()}
      >
        <ProductsSpotlighted />
        <ProductListWrappedByCategory />
        <ThankYouForYourOrderResponsiveDialog open={!!searchParams.get('thank-you')} />
      </Box>
    </SlideFromRight>
  )
}

export default Products
