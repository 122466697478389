import { useLayoutEffect, useRef } from 'react'

const useScrollToHash = () => {
  const { hash } = window.location
  const scrolledRef = useRef<boolean>(false)
  const hashRef = useRef<string | null>(null)

  useLayoutEffect(() => {
    if (!hash) {
      return
    }

    // We want to reset if the hash has changed
    if (hashRef.current !== hash) {
      hashRef.current = hash
      scrolledRef.current = false
    }

    if (scrolledRef.current) {
      return
    }

    // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
    const id = hash.replace('#', '')

    setTimeout(() => {
      const element: HTMLElement | null = document.getElementById(id)

      if (!element) {
        return
      }

      element.scrollIntoView({ behavior: 'smooth' })
      scrolledRef.current = true
    }, 1000)
  }, [])
}

export default useScrollToHash
