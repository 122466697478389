import StylesProductsServiceInterface from '~/src/pages/products/styles/StylesProductsServiceInterface'
import StylesProductsServiceStyleOne from '~/src/pages/products/styles/StylesProductsServiceStyleOne'
import StylesProductsServiceStyleTwo from '~/src/pages/products/styles/StylesProductsServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum, Theme } from '@eo-storefronts/eo-core'

const getProductsTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme, firmTheme?: Theme): StylesProductsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesProductsServiceStyleTwo(muiTheme, firmTheme)
    default:
      return new StylesProductsServiceStyleOne(muiTheme, firmTheme)
  }
}

export default getProductsTemplateService
