import { SxProps } from '@mui/material'
import StylesProductsServiceInterface from '~/src/pages/products/styles/StylesProductsServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesProductsServiceStyleOne extends TemplateServiceStyleBase implements StylesProductsServiceInterface {
  public getPageSx(): SxProps {
    return {}
  }

  public getContainerSx(): SxProps {
    return {
      pl: 4,
      pb: 4,
      [this.muiTheme.breakpoints.down('md')]: {
        pl: 0,
        pb: 2
      }
    }
  }
}
