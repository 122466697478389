import { Product } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import useLogAnalyticEcommerceEvent from '~/src/hooks/analytics/useLogAnalyticEcommerceEvent'
import { useEoValue } from '~/src/hooks/useEoState'
import { PRODUCTS_STATE } from '~/src/stores/product'

const useLogViewAllProducts = (): void => {
  const {
    logGoogleEvent: logGoogleAnalyticEvent,
    logFacebookEvent: logFacebookAnalyticEvent
  } = useLogAnalyticEcommerceEvent()
  const products = useEoValue(PRODUCTS_STATE)

  useEffect(() => {
    const productsArray = Object.values(products)

    logGoogleAnalyticEvent('view_item_list', {
      items: productsArray
    })

    logFacebookAnalyticEvent('ViewContent', {
      content_ids: productsArray.map((product: Product) => product.id.toString()),
      content_category: 'All products',
      content_name: 'Products',
      content_type: 'product_group',
      contents: productsArray
    })
  }, [])
}

export default useLogViewAllProducts
