import { Category } from '@eo-storefronts/eo-core'
import CategoryDetail from '~/src/components/category/CategoryDetail'
import { useEoValue } from '~/src/hooks/useEoState'
import { TOP_CATEGORIES_SELECTOR } from '~/src/stores/categories'
import useSubHeaderCategoryScrollListener from '~/src/hooks/layout/useSubHeaderCategoryScrollListener'
import useScrollToHash from '~/src/hooks/layout/useScrollToHash'

const ProductListWrappedByCategory = () => {
  const topCategories = useEoValue(TOP_CATEGORIES_SELECTOR)

  useScrollToHash()
  useSubHeaderCategoryScrollListener()

  if (!topCategories?.length) {
    return null
  }

  return (
    <>
      {topCategories.map((category: Category, index: number) => (
        <CategoryDetail
          categoryId={category.id}
          key={index} />
      ))}
    </>
  )
}

export default ProductListWrappedByCategory
