import getProductsTemplateService from '~/src/pages/products/styles/GetProductsTemplateService'
import StylesProductsServiceInterface from '~/src/pages/products/styles/StylesProductsServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'

const useGetProductsTemplate = (): StylesProductsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('products')
  const firmTheme = useEoValue(FIRM_THEME_SELECTOR)

  useLayout('products')

  return getProductsTemplateService(pageStyle, muiTheme, firmTheme)
}

export default useGetProductsTemplate
