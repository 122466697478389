import { Category } from '@eo-storefronts/eo-core'
import { useCallback, useLayoutEffect } from 'react'
import { useDebounce } from '~/src/hooks/useDebounce'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { CATEGORY_ID_STATE, TOP_CATEGORIES_SELECTOR } from '~/src/stores/categories'
import { Constants } from '~/src/helpers/constants'

const useSubHeaderCategoryScrollListener = () => {
  const lang = useEoValue(LANGUAGE_STATE)
  const [ selectedCategoryId, setSelectedCategoryId ] = useEoState(CATEGORY_ID_STATE)
  const topCategories = useEoValue(TOP_CATEGORIES_SELECTOR)

  const _getCategoryByName = (name: string): Category | undefined => {
    return topCategories.find((category: Category) => category.name[lang] === name)
  }

  const _scrollListener = useCallback(() => {
    const sections: NodeListOf<HTMLElement> | null = document.querySelectorAll<HTMLDivElement>('section')
    const scrollPaddingTop: number = parseFloat(document.documentElement.style.getPropertyValue('scroll-padding-top')) ?? 0

    if (!sections) {
      return
    }

    sections.forEach((section: HTMLElement) => {
      const top: number = window.scrollY + (scrollPaddingTop + Constants.SCROLL_PADDING_TOP_OFFSET)
      const offset: number = section.offsetTop
      const height: number = section.offsetHeight
      const id: string = section.getAttribute('id') ?? ''

      if (top >= offset && top < offset + height) {
        const category: Category | undefined = _getCategoryByName(decodeURIComponent(decodeURIComponent(id)))

        if (!category || category.id === selectedCategoryId) {
          return
        }

        setSelectedCategoryId(category.id)
      }
    })
  }, [ selectedCategoryId ])

  const _debouncedScrollListener = useDebounce(_scrollListener)

  useLayoutEffect(() => {
    window.addEventListener('scroll', _debouncedScrollListener)

    return () => {
      window.removeEventListener('scroll', _debouncedScrollListener)
    }
  }, [])
}

export default useSubHeaderCategoryScrollListener
