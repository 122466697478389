import { SxProps } from '@mui/material'
import StylesProductsServiceInterface from '~/src/pages/products/styles/StylesProductsServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesProductsServiceStyleTwo extends TemplateServiceStyleBase implements StylesProductsServiceInterface {
  public getPageSx(): SxProps {
    return {}
  }

  public getContainerSx(): SxProps {
    return {
      pb: 6,
      pr: 0,
      [this.muiTheme.breakpoints.down('md')]: {
        pb: 2
      }
    }
  }
}
